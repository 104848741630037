import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import YouTube from '../components/YouTube';
export const _frontmatter = {
  "title": "Wrap Your Brain Around Flex-Wrap",
  "date": "2017-09-04",
  "promo": "flexbox",
  "description": "Understand how flex-wrap works and the gotchas to watch out for.",
  "color": "#ff4783"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`flex-wrap is Flexbox pitfall #4. See the full list of `}<a parentName="p" {...{
        "href": "/post/flexbox-pitfalls"
      }}>{`Flexbox Pitfalls and How to Avoid Them`}</a>{`.`}</p>
    <p>{`Just when you think you're starting to get the hang of flexbox, you run into a situation where you don't have enough space on a single line for all of your flex items. No problem right, flex items can wrap! You add `}<inlineCode parentName="p">{`flex-wrap: wrap;`}</inlineCode>{` and watch as your precious flexbox confidence crumbles to ashes. But don't worry - there are just a couple of things to know about flex-wrap (and one thing to avoid entirely) and you'll be feeling flex-awesome again in no time.`}</p>
    <h2>{`when to wrap depends entirely on flex-basis`}</h2>
    <p>{`The first gotcha with `}<inlineCode parentName="p">{`flex-wrap`}</inlineCode>{` is that flex items will only begin to wrap if their sum total `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` is `}<strong parentName="p">{`greater`}</strong>{` than the size of the flex container. So to understand how `}<inlineCode parentName="p">{`flex-wrap`}</inlineCode>{` works you also need to have a solid understanding of `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{`, which has many nuances of its own. For example `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` falls back to `}<inlineCode parentName="p">{`width`}</inlineCode>{` when not defined. Also `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` is bounded by the item's `}<inlineCode parentName="p">{`min-width`}</inlineCode>{` and `}<inlineCode parentName="p">{`max-width`}</inlineCode>{` which affects its "final" `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` value. See `}<a parentName="p" {...{
        "href": "/post/the-difference-between-width-and-flex-basis"
      }}>{`The Difference Between Width and Flex-Basis`}</a>{` to fully understand this property. If your flex items aren't wrapping like you expect them to, it's because of `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{`.`}</p>
    <p>{`Flexbox Zombies `}<a parentName="p" {...{
        "href": "https://mastery.games/flexboxzombies/chapter/7/level/1"
      }}>{`chapter 7`}</a>{` covers `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` in detail.`}</p>
    <h2>{`flex-wrap changes how things shrink`}</h2>
    <p>{`By default flex items shrink as soon as their total accumulative `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` is lower than the size of their parent flex container. In this example our container is `}<inlineCode parentName="p">{`400px`}</inlineCode>{`, and the sum total of all our final `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` values is `}<inlineCode parentName="p">{`800px`}</inlineCode>{` (`}<inlineCode parentName="p">{`4`}</inlineCode>{` `}<em parentName="p">{` `}<inlineCode parentName="em">{`200px`}</inlineCode>{`). Since that total (`}<inlineCode parentName="em">{`800px`}</inlineCode>{`) is larger than the container size (`}<inlineCode parentName="em">{`400px`}</inlineCode>{`) the flex items `}</em>{`shrink`}{`*`}{` to fit.`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .container {
    display: flex;
    width: 400px;
  }\n
  .item {
    height: 200px;
    /* items have shrunk already */
    flex-basis: 200px;
  }
`} html={`
  <div class="container">
    <div class="item red"></div>
    <div class="item blue"></div>
    <div class="item green"></div>
    <div class="item purple"></div>  
  </div>   
`} mdxType="CodeExample" />
    <p>{`But once you tell your container to `}<inlineCode parentName="p">{`flex-wrap`}</inlineCode>{`, then wrapping become a priority over shrinking. Keep this formula in mind:`}</p>
    <div className="formula">
  continue wrapping to new lines until each flex item has its own line, then
  begin shrinking items once their flex-basis is greater than the container
  size.
    </div>
    <p>{`Notice in this example since we're wrapping, the items haven't even started to shrink yet because they just keep wrapping to get their own line and their `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` (`}<inlineCode parentName="p">{`200px`}</inlineCode>{`) is still lower than the container size (`}<inlineCode parentName="p">{`250px`}</inlineCode>{`).`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .container {
    display: flex;
    flex-wrap: wrap;
    width: 250px;
  }\n
  .item {
    height: 200px;
    /* items haven't starting shrinking yet */
    flex-basis: 200px;
  }
`} fixedCSS={`
  
`} html={`
  <div class="container">
    <div class="item red"></div>
    <div class="item blue"></div>
    <div class="item green"></div>
    <div class="item grey"></div>
    <div class="item purple"></div>  
  </div>
`} mdxType="CodeExample" />
    <p>{`But as soon as each item has its own line and the container size is lower than an items `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{`, then the item will shrink:`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .container {
    display: flex;
    flex-wrap: wrap;
    width: 100px;
  }\n
  .item {
    height: 200px;
    /* items haven't starting shrinking yet */
    flex-basis: 200px;
  }   
`} html={`
  <div class="container">
    <div class="item red"></div>
    <div class="item blue"></div>
    <div class="item green"></div>
    <div class="item grey"></div>
    <div class="item purple"></div>  
  </div> 
`} mdxType="CodeExample" />
    <h2>{`flex-wrap changes how things grow`}</h2>
    <p><inlineCode parentName="p">{`flex-wrap`}</inlineCode>{` also affects how flex items grow. By default if you have items set to grow (`}<inlineCode parentName="p">{`flex-grow: 1`}</inlineCode>{`) they'll grow on a single line whenever there's free space available. In this example the `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` sum is `}<inlineCode parentName="p">{`400px`}</inlineCode>{` (`}<inlineCode parentName="p">{`2 * 200px`}</inlineCode>{`) and the container is `}<inlineCode parentName="p">{`500px`}</inlineCode>{`. So the items `}<em parentName="p">{`grow`}</em>{` to fill the extra space:`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .container {
    display: flex;
    width: 500px;
  }\n
  .item {
    height: 200px;
    flex-basis: 200px;
    flex-grow: 1;
  }
`} fixedCSS={`
  
`} html={`
  <div class="container">
    <div class="item grey"></div>
    <div class="item purple"></div>  
  </div>   
`} mdxType="CodeExample" />
    <p>{`But when you use wrapping, items treat the row they're on as the only space that matters, and will grow to fill any free space `}<strong parentName="p">{`in their own line`}</strong>{`.`}</p>
    <p>{`In this example there are three items at `}<inlineCode parentName="p">{`200px`}</inlineCode>{` `}<inlineCode parentName="p">{`flex-basis`}</inlineCode>{` each, inside of a `}<inlineCode parentName="p">{`500px`}</inlineCode>{` wide container. There's not enough room for them to all fit so the last item drops to a new line. It then treats that line as its very own and grows to fill the entire space inside of it. The remaining two items in the original line also grow a little bit to share the newly created `}<inlineCode parentName="p">{`100px`}</inlineCode>{` of free space in the first line.`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .container {
    display: flex;
    width: 500px;
    flex-wrap: wrap;
  }\n
  .item {
    height: 150px;
    flex-basis: 200px;
    flex-grow: 1;
  }   
`} html={`
  <div class="container">
    <div class="item grey"></div>
    <div class="item purple"></div>  
    <div class="item blue"></div> 
  </div>   
`} mdxType="CodeExample" />
    <h2>{`new property to learn`}</h2>
    <p>{`Once your flex items wrap to a new line, you need to learn how to control the positioning of those lines. This is what `}<inlineCode parentName="p">{`align-content`}</inlineCode>{` is all about. With it you can do cool things like space out the wrapped lines:`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .container {
    display: flex;
    width: 200px;
    height: 350px;
    flex-wrap: wrap;
    align-content: space-between;
  }\n
  .item {
    height: 100px;
    flex-basis: 100px;
  }   
`} html={`
  <div class="container">
    <div class="item purple"></div>  
    <div class="item blue"></div> 
  </div>   
`} mdxType="CodeExample" />
    <p>{`Or bunch the lines together in the center of the flex container:`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .container {
    display: flex;
    width: 200px;
    height: 350px;
    flex-wrap: wrap;
    align-content: center;
  }\n
  .item {
    height: 100px;
    flex-basis: 100px;
  }   
`} html={`
  <div class="container">
    <div class="item purple"></div>  
    <div class="item blue"></div> 
  </div>   
`} mdxType="CodeExample" />
    <p>{`Check out how I used `}<inlineCode parentName="p">{`flex-wrap`}</inlineCode>{` and `}<inlineCode parentName="p">{`align-content`}</inlineCode>{` to great effect on Link's hearts in the Zelda UI:`}</p>
    <YouTube id="7zBpd3wWsnU" start={508} mdxType="YouTube" />
    <p>{`Flexbox Zombies `}<a parentName="p" {...{
        "href": "https://mastery.games/flexboxzombies/chapter/10/level/1"
      }}>{`chapter 10`}</a>{` covers all the `}<inlineCode parentName="p">{`align-content`}</inlineCode>{` options in detail.`}</p>
    <h2>{`wrap-reverse is for masochists only`}</h2>
    <p>{`The final `}<inlineCode parentName="p">{`flex-wrap`}</inlineCode>{` pitfall to watch out for is that `}<inlineCode parentName="p">{`flex-wrap: wrap-reverse`}</inlineCode>{` will give your brain a proper mind-bending from which you may never recover. The purpose of this value is to let you `}<em parentName="p">{`reverse the order of your wrapped lines`}</em>{`. Usually new wrap lines are created after the existing line(s) like in all the examples above. That means new lines usually show up `}<em parentName="p">{`below`}</em>{` when doing horizontal layouts, or to the `}<em parentName="p">{`right`}</em>{` when doing vertical layouts. But setting `}<inlineCode parentName="p">{`flex-wrap: wrap-reverse`}</inlineCode>{` changes that so that new lines show up `}<em parentName="p">{`before`}</em>{` existing lines. Above existing line(s) for horizontal layouts, and to the left of them for vertical layouts.`}</p>
    <p>{`In this example the blue item didn't have enough room to fit on the same line so it moved to its own line. But this time because of `}<inlineCode parentName="p">{`flex-wrap: wrap-reverse`}</inlineCode>{` that new line is placed `}<em parentName="p">{`above`}</em>{` the original line.`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .container {
    display: flex;
    width: 500px;
    flex-wrap: wrap-reverse;
  }\n
  .item {
    height: 150px;
    flex-basis: 200px;
    flex-grow: 1;
  }   
`} html={`
  <div class="container">
    <div class="item grey"></div>
    <div class="item purple"></div>  
    <div class="item blue"></div> 
  </div>   
`} mdxType="CodeExample" />
    <p>{`If that was all that `}<inlineCode parentName="p">{`flex-wrap: wrap-reverse`}</inlineCode>{` changed it wouldn't be too bad. But get this. It also reverses the `}<inlineCode parentName="p">{`align-items`}</inlineCode>{` and `}<inlineCode parentName="p">{`align-self`}</inlineCode>{` properties! Even when there's plenty of space so no wrapping is needed. Up is down, down is up, right is left, left is right.`}</p>
    <p>{`See for yourself: in the code below delete the `}<inlineCode parentName="p">{`flex-wrap: wrap-reverse;`}</inlineCode>{` line and watch your world flip back right-side up.`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .container {
    outline: 5px solid black;
    display: flex;
    width: 500px;
    height: 500px;
    flex-wrap: wrap-reverse;
    /* 
    wrap-reverse made flex-end 
    be at the TOP! 
    */
    align-items: flex-end;
  }\n
  .item {
    height: 100px;
    flex-basis: 100px;
  }\n
  .purple {
    /* 
    wrap-reverse made flex-start 
    be at the BOTTOM! 
    */
    align-self: flex-start;
  }   
`} html={`
  <div class="container">
    <div class="item grey"></div>
    <div class="item purple"></div>  
    <div class="item blue"></div> 
  </div>   
`} mdxType="CodeExample" />
    <p><inlineCode parentName="p">{`flex-wrap: wrap-reverse`}</inlineCode>{` does this same dizzying trick to the `}<inlineCode parentName="p">{`align-content`}</inlineCode>{` property - reversing the alignment axis - making `}<inlineCode parentName="p">{`flex-end`}</inlineCode>{` bunch the lines to the `}<em parentName="p">{`top`}</em>{` of the container rather than to the bottom. Thanks `}<inlineCode parentName="p">{`wrap-reverse`}</inlineCode>{`!`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .container {
    display: flex;
    width: 500px;
    height: 400px;
    flex-wrap: wrap-reverse;
    /* 
    flex-wrap: wrap-reverse 
    made flex-end be the TOP 
    */
    align-content: flex-end;
  }\n
  .item {
    height: 100px;
    flex-basis: 100px;
  }   
`} html={`
  <div class="container">
    <div class="item grey"></div>
    <div class="item purple"></div>  
    <div class="item blue"></div> 
    <div class="item green"></div>
    <div class="item red"></div>
  </div>   
`} mdxType="CodeExample" />
    <p>{`If you're some kind of super human genius and can think of a way to keep things straight - "the enemy gate is down" or something - then go for it. Otherwise `}<inlineCode parentName="p">{`wrap-reverse`}</inlineCode>{` should be avoided. I haven't found a use for it yet myself. Most of the time you can just change the source order of your DOM elements anyways to get the effect you're after. Even if you never use it yourself, it's good to know what it does in case you encounter it in the wild.`}</p>
    <h2>{`Challenge`}</h2>
    <p>{`Wrap your brain (see what I did there...) around the nuances of `}<inlineCode parentName="p">{`flex-wrap`}</inlineCode>{` by getting some practice: find a UI you like where `}<inlineCode parentName="p">{`flex-wrap`}</inlineCode>{` would be a good fit and build it yourself. Cement these concepts into muscle memory by shooting the crossbow in Flexbox Zombies `}<a parentName="p" {...{
        "href": "https://mastery.games/flexboxzombies/chapter/9/level/1"
      }}>{`chapter 9`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      